var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"safe_text"},[(_vm.user.male || !_vm.object.female_text)?_c('div',{staticClass:"safe_text__content",staticStyle:{"font-size":"18px"},domProps:{"innerHTML":_vm._s(
      _vm.object.text
        .replace(/%name%/g, _vm.user.first_name)
        .replace(/%surname%/g, _vm.user.last_name)
        .replace(/%points%/g, _vm.pageData.user_progress.points)
        .replace(/https:\/\/newapi.mysimulator.ru/g, _vm.$url)
    )}}):_c('div',{staticClass:"safe_text__content",staticStyle:{"font-size":"18px"},domProps:{"innerHTML":_vm._s(
      _vm.object.female_text
        .replace(/%name%/g, _vm.user.first_name)
        .replace(/%surname%/g, _vm.user.last_name)
        .replace(/%points%/g, _vm.pageData.user_progress.points)
        .replace(/https:\/\/newapi.mysimulator.ru/, _vm.$url)
    )}}),(_vm.button)?_c('FormButton',{attrs:{"loading":_vm.loading,"text":_vm.simInfo.button_next_text ? _vm.simInfo.button_next_text : 'Далее',"size":"medium-large","color":"primary"},on:{"click":_vm.nextStory}}):_vm._e()],1)}
var staticRenderFns = []

export { render, staticRenderFns }