import { render, staticRenderFns } from "./OpenQuestion.vue?vue&type=template&id=2586112c&scoped=true&"
import script from "./OpenQuestion.vue?vue&type=script&lang=js&"
export * from "./OpenQuestion.vue?vue&type=script&lang=js&"
import style0 from "./OpenQuestion.vue?vue&type=style&index=0&id=2586112c&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "2586112c",
  null
  
)

export default component.exports