<template>
  <div class="safe_text" v-if="content">
    <div v-html="content.content"></div>
    <FormButton
      :loading="loading"
      v-if="button && content.complete"
      :text="simInfo.button_next_text ? simInfo.button_next_text : 'Далее'"
      size="medium-large"
      color="primary"
      @click="nextStory"
    />
  </div>
</template>

<script>
import FormButton from "@/components/global/form/FormButton";
import axios from "axios";
import { mapGetters } from "vuex";

export default {
  name: "Ext",
  props: ["object", "button", "page", "sequence_no", "place_id"],
  components: {
    FormButton,
  },
  data() {
    return {
      content: null,
    };
  },
  computed: {
    ...mapGetters({
      simInfo: "simulator/getSimInfo",
      loading: "utility/getCourseTaskLoading",
      user: "user/getMeInfo",
      pageData: "page/getPageInfo",
    }),
  },
  mounted() {
    // if (!this.content) {
    //   this.getData();
    // }
    // setInterval(() => {
    //   this.getData();
    // }, 3000);
  },
  methods: {
    async getData() {
      const data = new FormData();
      data.append("user_id", this.user.id);
      data.append("user_email", this.user.email);
      axios.post(this.object.title, data).then((response) => {
        if (!this.content || this.content.version < response.data.version) {
          this.content = response.data;
          console.log(123);
        }
      });
    },
    async nextStory() {
      this.$store.commit("utility/SET_COURSE_TASK_LOADING", true);
      await this.$store.dispatch("page/PAGE_NEXT", {
        page_id: this.page,
        place: this.place_id,
        seq_no: this.sequence_no,
      });
    },
  },
};
</script>
