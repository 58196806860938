var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"course-button",class:[
    _vm.type,
    { inactive: !_vm.active, selected: _vm.clicked, incorrect_nochosen: _vm.incorrect_nochosen, correct_nochosen: _vm.correct_nochosen } ],domProps:{"innerHTML":_vm._s(
    _vm.text
      .replace(/%name%/g, _vm.userInfo.first_name)
      .replace(/%surname%/g, _vm.userInfo.last_name)
      .replace(/https:\/\/newapi.mysimulator.ru/g, _vm.$url)
  )},on:{"click":function($event){_vm.$emit('click');
    _vm.clicked = !_vm.clicked;}}})}
var staticRenderFns = []

export { render, staticRenderFns }